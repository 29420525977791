@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

$font-web: 'Poppins';
$font-web_weight: 700;
$font-web_weight_medium: 500;
$font-web_weight_light: 300;

@mixin mouse_over {
	opacity: .7;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-ms-transition: 0.5s;
}

@mixin bk_gradient {
	background: #3abbf7; /* Old browsers */
	background-image: -moz-linear-gradient(left,  #3abbf7 0%, #aaf9df 50%, #3abbf7 100%);
	background-image: -webkit-linear-gradient(left,  #3abbf7 0%, #aaf9df 50%, #3abbf7 100%);
	background-image: linear-gradient(to right,   #3abbf7 0%, #aaf9df 50%, #3abbf7 100%);
}

@mixin bk_gradient02 {
	background: #228be6; /* Old browsers */
	background-image: -moz-linear-gradient(left,  #228be6 0%, #77d4ff 100%);
	background-image: -webkit-linear-gradient(left,  #228be6 0%, #77d4ff 100%);
	background-image: linear-gradient(to right,   #228be6 0%, #77d4ff 100%);
}

@mixin bk_gradient03 {
	background: #228be6; /* Old browsers */
	background: -moz-linear-gradient(left,  #228be6 0%, #77d4ff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #228be6 0%,#77d4ff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #228be6 0%,#77d4ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#228be6', endColorstr='#77d4ff',GradientType=1 ); /* IE6-9 */
}

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
	#MainImg{
		position: relative;
		background-size: auto 100%, cover;
		#MainImgInner{
			position: static;
			h2{
				position: absolute;
				text-align: left;
				font-weight: bold;
				color: #fff;
				b, span, em{
					display: block;
					line-height: 1;
				}
				b{
					position: relative;
					&::after{
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						display: block;
						@include bk_gradient03();
					}
				}
				em{
					span{
						display: inline-block;
						@include bk_gradient03();
					}
				}
			}
			p{
				margin-bottom: 0;
				position: absolute;
				box-shadow: 0 0 30px rgba(0, 0, 0, .05);
				background-repeat: no-repeat;
				background-position: center center;
				text-indent: -9999px;
			}
		}
	}
	#Main{
		.titleTypeBasic{
			line-height: 1;
			text-align: center;
			font-family: $font-web;
			font-weight: $font-web_weight;
			color: #3ea3ee;
			font-size: 52px;
			span{
				display: block;
				font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
				font-size: 20px;
				font-weight: 500;
				color: #999;
			}
		}
		#ContBox01, #ContBox06{
			.titleTypeBasic{
				background-color: #f0f6f9;
				font-size: 36px;
			}
			.listTypeBlog{
				&> li{
					dl{
						dd.title{
							overflow: hidden;
							font-size: 14px;
						}
					}
				}
			}
		}
		#ContBox02{
			.contSubBox01{
				.imgBox{
					img{
						width: 100%;
						height: auto;
					}
					p{
						margin-bottom: 0;
						text-align: center;
						font-size: 14px;
						font-weight: bold;
					}
				}
				.textBox{
					h4{
						font-size: 20px;
						font-weight: bold;
					}
				}
			}
		}
		#ContBox03{
			.contSubBox{
				.subBox{
					&.subBox01{
						position: relative;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: cover;
						h4{
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border: 4px solid #fff;
							width: 300px;
							height: 300px;
							letter-spacing: .1em;
							font-size: 28px;
							font-weight: bold;
							color: #fff;
							.btnTypeInv{
								margin-top: 5px;
								background-color: #f0f6f9;
								&::after{
									display: none;
								}
								span{
									position: relative;
									display: inline-block;
									padding-right: 14px;
									&::after{
										content: "";
										position: absolute;
										top: 50%;
										right: 1px;
										display: block;
										width: 6px;
										height: 10px;
										background-repeat: no-repeat;
										background-position: center center;
										background-image: url(../img/contents/arrow_01_b.png);
										background-size: 6px auto;
										-webkit-transform: translate(0, -50%);
										transform: translate(0, -50%);
									}
								}
							}
						}
					}
					&.subBox02{
						background-color: #f0f6f9;
						ul{
							li{
								&:last-of-type{
									margin-bottom: 0;
								}
								.btnTypeGradation{
									font-size: 14px;
									&::after{
										width: 6px;
										height: 11px;
										right: 12px;
									}
								}
							}
						}
					}
				}
			}
			.contSubBox01{
				.subBox{
					&.subBox01{
						background-image: url("../img/contents/top/index_kenkyunaiyo1.jpg");
					}
				}
			}
			.contSubBox02{
				.subBox{
					&.subBox01{
						background-image: url("../img/contents/top/index_kenkyunaiyo2.jpg");
					}
				}
			}
			.contSubBox03{
				.subBox{
					&.subBox01{
						background-image: url("../img/contents/top/index_kenkyunaiyo3.jpg");
					}
				}
			}
			.contSubBox04{
				.btnTypeGradation{
					padding-top: 67px;
					height: 170px;
					&::before{
						content: "";
						position: absolute;
						background-repeat: no-repeat;
						background-position: center center;
						background-image: url("../img/contents/top/text_01.png");
					}
					span{
						position: relative;
						padding-right: 36px;
						letter-spacing: .05em;
						font-size: 20px;
						&::after{
							content: "";
							position: absolute;
							top: 50%;
							right: 0px;
							transform: translate(0, -50%);
							display: block;
							width: 24px;
							height: 24px;
							background-repeat: no-repeat;
							background-position: center center;
							background-image: url("../img/contents/plus_01.png");
							background-size: 24px auto;
						}
					}
					&.active{
						span{
							&::after{
								background-image: url("../img/contents/minus_01.png");
							}
						}
					}
				}
				.wrapBox{
					background-color: #f0f6f9;
					.subBox{
						margin: 0 auto;
						border-bottom: 1px solid #fff;
						&:last-of-type{
							border-bottom: none;
						}
						.imgBox{
							img{
								width: 100%;
								height: auto;
							}
						}
						.textBox{
							h5{
								font-size: 20px;
								font-weight: bold;
							}
							span{
								display: block;
								font-size: 14px;
								color: #999;
							}
							p:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
		#ContBox04{
			.contSubBox{
				&:last-of-type{
					margin-bottom: 0;
				}
				.subBox{
					background-color: #f2f2f2;
					.imgBox{
						dl{
							dt{
								position: relative;
								background-color: #a8a8a8;
								img{
									width: 100%;
									height: auto;
								}
								span{
									position: absolute;
									left: 0;
									bottom: 0;
									display: inline-block;
									padding: 4px 4px 0;
									height: 20px;
									@include bk_gradient02();
									line-height: 1;
									font-size: 12px;
									color: #fff;
								}
							}
							dd{
								line-height: 1;
								font-size: 20px;
								font-weight: bold;
								span{
									display: block;
									letter-spacing: .06em;
									font-family: $font-web;
									font-size: 14px;
									font-weight: $font-web_weight_medium;
									color: #aeaeae;
								}
							}
						}
					}
					.infoBox{
						table{
							tr{
								th{
									font-weight: bold;
									font-size: 14px;
								}
								td{
									line-height: 32px;
									font-size: 16px;
									p{
										&:last-of-type{
											margin-bottom: 0;
										}
									}
									a{
										text-decoration: none;
									}
								}
							}
						}
					}
				}
				&.contSubBox05, &.contSubBox06{
					.subBox01{
						ul{
							font-size: 0;
							li{
								display: inline;
								font-size: 16px;
								&::after{
									content: "/";
									color: #999;
								}
								&:last-of-type{
									&::after{
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
		#ContBox05{
			background-color: #f0f6f9;
			.listTypeArc{
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
			}
			.btnTypeGradation{
				&::after{
					width: 7px;
					height: 10px;
					background-image: url("../img/contents/arrow_01_w.png");
					background-size: 7px auto;
				}
			}
		}
	}
}

/*
テロメア研究
-------------------------------------*/
#PageTelomere.pageIndex {
	#MainImg{
		background-image: url("../img/contents/telomere/lower_pagettl_telomerekenkyu.jpg")
	}
	.researchBox{
		.contBox{
			&::before{
				background-image: url("../img/contents/telomere/Telomere.png");
			}
		}
	}
}

/*
膵臓がん研究
-------------------------------------*/
#PagePancreas.pageIndex {
	#MainImg{
		background-image: url("../img/contents/pancreas/lower_pagettl_suizogankenkyu.jpg")
	}
	.researchBox{
		.contBox{
			&::before{
				background-image: url("../img/contents/pancreas/Pancreatic.png");
			}
		}
	}
}

/*
諸臓器のがん
-------------------------------------*/
#PageElderly.pageIndex {
	#MainImg{
		background-image: url("../img/contents/elderly/lower_pagettl_shozokinogan.jpg")
	}
	.researchBox{
		.contBox{
			&::before{
				background-image: url("../img/contents/elderly/Various organs .png");
			}
		}
	}
}

/*
業績一覧
-------------------------------------*/
#PageAchievement{
	#MainImg{
		background-image: url("../img/contents/achievement/lower_pagettl_gyosekiichiran.jpg")
	}
	#Main{
		.listTypeCat{
			&> ul{
				display: flex;
				flex-wrap: wrap;
				&> li{
					text-align: left;
					font-size: 14px;
					color: #c9c9c9;
					a{
						position: relative;
						text-decoration: none;
						display: block;
						text-decoration: none;
						&::after{
							content: "";
							position: absolute;
							top: 50%;
							right: 0;
							transform: translate(0, -50%);
							display: block;
							width: 6px;
							height: 10px;
							background-repeat: no-repeat;
							background-position: center center;
							background-image: url("../img/contents/arrow_06_b.png");
							background-size: 6px auto;
						}
					}
					dl{
						dt{
							font-size: 16px;
							color: #333;
						}
					}
					&:first-of-type{
						order: 1;
						a{
							font-size: 16px;
						}
					}
					&:nth-of-type(2) { order: 3; }
					&:nth-of-type(3) { order: 2; }
					&:last-of-type { order: 4; }
				}
				.active{
					a{
						color: #c9c9c9;
						&::after{
							background-image: url("../img/contents/arrow_06_g.png");
						}
					}
				}
			}
		}
		#ContBox01{
			background-color: #f0f6f9;
			.wrap{
				border-top: 1px solid #fff;
			}
		}
	}
}

/*
業績一覧（トップページ）
-------------------------------------*/
#PageAchievement.pageIndex{
}

/*
業績一覧（サブカテゴリアーカイブ）
-------------------------------------*/
#PageAchievement.pageCategory{
}

/*
業績一覧（記事詳細）
-------------------------------------*/
#PageAchievement.pageEntry{
	#Main{
		#ContBox01{
			.entry{
				line-height: 28px;
				font-size: 14px;
				color: #999;
				em{
					font-style: italic;
				}
				strong{
					font-weight: bold;
				}
				p{
					line-height: 28px;
					text-align: left;
					font-size: 14px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
			.more{
				margin-top: 15px;
				line-height: 32px;
				font-size: 16px;
			}
		}
	}
}

/*
お知らせ
-------------------------------------*/
#PageNews{
	#MainImg{
		background-image: url("../img/contents/news/lower_pagettl_oshirase.jpg")
	}
	#Main{
		.listTypeCat{
			ul{
				li{
					font-size: 14px;
					color: #c9c9c9;
					a{
						display: block;
						text-decoration: none;
					}
					&.active a{
						color: #c9c9c9;
					}
				}
			}
		}
		#ContBox01{
			background-color: #f6f6f6;
			.wrap{
				border-top: 1px solid #fff;
			}
		}
		.pageMove{
			ul{
				li{
					a{
						background-color: #77d4ff;
						color: #fff;
					}
					&.prev{
						a{
							span{
								&:after{
									background-image: url("../img/contents/arrow_01_w_r.png");
								}
							}
						}
					}
					&.next{
						a{
							span{
								&:after{
									background-image: url("../img/contents/arrow_01_w.png");
								}
							}
						}
					}
					&.top{
						a{
							span{
								&:after{
									background-image: url("../img/contents/arrow_02_w.png");
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
お知らせ（トップページ）
-------------------------------------*/
#PageNews.pageIndex{
}

/*
お知らせ（サブカテゴリアーカイブ）
-------------------------------------*/
#PageNews.pageCategory{
}

/*
お知らせ（記事詳細）
-------------------------------------*/
#PageNews.pageEntry{
}

@media print,screen and (min-width: 768px) {
	/*
	トップページ
	-------------------------------------*/
	#Page.pageIndex {
		#MainImg{
			height: 660px;
			background-position: left top, center center;
			background-image: url("../img/contents/top/shape_01.png"), url("../img/contents/top/index_mian.jpg");
			#MainImgInner{
				h2{
					top: 50%;
					left: 140px;
					transform: translate(0, -50%);
					margin-top: -19px;
					b{
						margin-bottom: 22px;
						padding-bottom: 10px;
						letter-spacing: -.05em;
						font-size: 36px;
						&::after{
							width: 98px;
							height: 4px;
						}
					}
					&> span{
						margin-bottom: 19px;
						letter-spacing: .13em;
						font-size: 18px;
					}
					em{
						span{
							padding: 15px 21px;
							font-size: 52px;
							letter-spacing: .18em;
							&:first-of-type{
								margin-bottom: 20px;
								padding-right: 8px;
							}
							&:last-of-type{
								padding-right: 0;
							}
						}
					}
					.btnTypeDonate{
						margin-top: 50px;
						margin-bottom: -36px;
					}
				}
				p{
					right: 0;
					bottom: -51px;
					width: 500px;
					height: 103px;
					background-image: url("../img/contents/top/banner_01_pc.png");
					background-size: 500px auto;
				}
			}
		}
		#Main{
			padding-top: 150px;
			.titleTypeBasic{ 
				margin-bottom: 59px;
				span{
					margin-top: 8px;
					letter-spacing: .15em;
				}
			}
			#ContBox01, #ContBox06{
				background: #f0f6f9; /* Old browsers */
				background: -moz-linear-gradient(left,  #f0f6f9 50%, #ffffff 50%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left,  #f0f6f9 50%,#ffffff 50%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right,  #f0f6f9 50%,#ffffff 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f6f9', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
				.titleTypeBasic{
					float: left;
					margin-bottom: 0;
					padding-top: 80px;
					text-align: left;
					letter-spacing: -.01em;
					width: 187px;
					.btnTypeInv{
						margin: 24px 0 0 11px;
						line-height: 2;
					}
				}
				.listTypeBlog{
					float: right;
					padding-left: 61px;
					width: 813px;
					min-height: 251px;
					background-color: #fff;
					&> li{
						padding-top: 10px;
						padding-bottom: 10px;
						dl{
							dd.title{
								min-height: 0;
								line-height: 1;
								white-space: nowrap;
								text-overflow: ellipsis;
								-webkit-text-overflow: ellipsis;
								padding-top: 7px;
								width: 547px;
							}
						}
					}
				}
			}
			#ContBox02{
				padding-top: 113px;
				padding-bottom: 60px;
				background-repeat: repeat-x;
				background-position: left top;
				background-image: url("../img/contents/wall_01.jpg");
				background-size: auto 187px;
				background-color: #f2f2f2;
				.titleTypeBasic{
					margin-bottom: 0;
					text-align: left;
					span{
						position: relative;
						top: -9px;
						display: inline-block;
						margin-top: 0;
						padding-left: 15px;
						vertical-align: bottom;
					}
				}
				.contSubBox01{
					&::after{
						content:"";
						display:block;
						clear:both;
					}
					.imgBox{
						float: right;
						margin-top: -45px;
						width: 294px;
						p{
							margin-top: 9px;
						}
					}
					.textBox{
						float: left;
						padding-top: 76px;
						width: 650px;
						h4{
							margin-bottom: 29px;
						}
						.btnTypeDonate{
							margin-top: 31px;
						}
					}
				}
			}
			#ContBox03{
				padding-top: 113px;
				.contSubBox{
					display: flex;
					&:nth-of-type(2n){
						flex-flow: row-reverse;
					}
					.subBox{
						width: 50%;
						height: 640px;
						&.subBox01{
							.btnTypeInv:hover span::after{
								background-image: url("../img/contents/arrow_01_w.png");
							}
						}
						&.subBox02{
							display: flex;
							justify-content: center;
							flex-direction: column;
							padding: 0 60px;
							ul{
								margin-top: 7px;
								li{
									margin-bottom: 20px;
									.btnTypeGradation{
										border-radius: 23px;
										padding-top: 9px;
										height: 46px;
									}
								}
							}
						}
					}
				}
				.contSubBox04{
					.btnTypeGradation{
						&::before{
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 968px;
							height: 66px;
							background-size: auto;
						}
					}
					.wrapBox{
						padding-top: 60px;
						padding-bottom: 60px;
						.subBox{
							padding-top: 30px;
							padding-bottom: 10px;
							width: 1000px;
							&::after{
								content:"";
								display:block;
								clear:both;
							}
							.imgBox{
								float: left;
								width: 180px;
								padding-bottom: 20px;
							}
							.textBox{
								float: right;
								margin-top: -10px;
								width: 792px;
								h5{
									margin-bottom: -4px;
									letter-spacing: -.001em;
								}
								span{
									margin-bottom: 6px;
								}
							}
						}
					}
				}
			}
			#ContBox04{
				.innerBasic{
					padding-top: 113px;
					padding-bottom: 113px;
				}
				.titleTypeBasic{
					margin-bottom: 47px;
				}
				.contSubBox{
					margin-bottom: 49px;
					.subBox{
						margin-bottom: 10px;
						padding: 60px 60px 39px;
						&::after{
							content:"";
							display:block;
							clear:both;
						}
						.imgBox{
							float: left;
							margin-bottom: 21px;
							width: 180px;
							dl{
								dt{
									margin-bottom: 20px;
									width: 180px;
									height: 180px;
								}
								dd{
									span{
										margin-top: 11px;
									}
								}
							}
						}
						.infoBox{
							float: right;
							width: 660px;
							table{
								tr{
									th, td{
										border-top: 1px solid #fff;
										vertical-align: top;
									}
									th{
										padding-top: 15px;
										padding-right: 10px;
										padding-bottom: 14px;
										width: 80px;
									}
									td{
										padding-top: 14px;
										padding-bottom: 13px;
										.textTypeUrl{
											display: inline-block;
											margin-top: -5px;
											line-height: 24px;
											font-size: 14px;
										}
										a:hover{
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
					&.contSubBox04{
						&::after{
							content:"";
							display:block;
							clear:both;
						}
						.subBox{
							float: left;
							margin-right: 10px;
							margin-bottom: 0;
							padding-right: 0;
							// width: 495px;
							width: 100%;
							&:nth-of-type(2n){
								margin-right: 0;
							}
							.imgBox{
								margin-bottom: 19px;
								width: 100%;
								dl{
									display: flex;
									align-items: center;
									dt{
										margin-bottom: 0;
									}
									dd{
										padding-left: 42px;
									}
								}
							}
						}
					}
					&.contSubBox05, &.contSubBox06{
						.subBox01{
							ul{
								margin-top: -6px;
								margin-bottom: 6px;
								line-height: 28px;
								li{
									&::after{
										padding-right: 15px;
										padding-left: 16px;
									}
								}
							}
						}
					}
				}
				
			}
			#ContBox05{
				.innerBasic{
					padding-top: 112px;
					padding-bottom: 165px;
				}
				.listTypeArc{
					padding-bottom: 19px;
				}
				.btnTypeGradation{
					margin-top: 60px;
					&::after{
						right: 24px;
					}
				}
			}
			#ContBox06{
				margin-top: 80px;
				margin-bottom: 93px;
				.titleTypeBasic{
					.btnTypeInv{
						margin-left: 25px;
					}
				}
			}
		}
	}

	/*
	テロメア研究
	-------------------------------------*/
	#PageTelomere.pageIndex {
		.researchBox{
			.contBox{
				&::before{
					top: 100px;
					width: 728px;
					height: 102px;
				}
				&:nth-of-type(2n+1){
					&::before{
						margin-left: -90px;
					}
				}
			}
		}
	}

	/*
	膵臓がん研究
	-------------------------------------*/
	#PagePancreas.pageIndex {
		.researchBox{
			.contBox{
				&::before{
					top: 100px;
					width: 905px;
					height: 101px;
				}
				&:nth-of-type(2n+1){
					&::before{
						margin-left: -260px;
					}
				}
			}
		}
	}

	/*
	諸臓器のがん
	-------------------------------------*/
	#PageElderly.pageIndex {
		.researchBox{
			.contBox{
				&::before{
					top: 120px;
					width: 1012px;
					height: 80px;
				}
				.contSubBox01{
					padding-top: 50px;
					padding-bottom: 46px;
					
				}
				&:nth-of-type(2n+1){
					&::before{
						margin-left: -370px;
					}
				}
				&:nth-of-type(2n){
					padding-top: 160px;
					background-size: 1px 290px;
				}
			}
		}
	}

	/*
	業績一覧
	-------------------------------------*/
	#PageAchievement{
		#Main{
			.listTypeCat{
				padding-top: 74px;
				padding-bottom: 71px;
				min-width: 1000px;
				&> ul{
					margin: 0 auto;
					width: 1000px;
					text-align: center;
					background: url("../img/contents/achievement/parts_01.gif") repeat-y center top;
					&::after{
						content:"";
						display:block;
						clear:both;
					}
					&> li{
						float: left;
						margin-top: 14px;
						padding-right: 60px;
						padding-left: 60px;
						text-align: left;
						a{
							&:hover{
								text-decoration: underline;
							}
						}
						dl{
							dt{
								margin-bottom: 5px;
								font-size: 16px;
								cursor: text !important;
							}
							dd{
								display: block !important;
								height: auto !important;
								ul{
									li{
										margin-bottom: 1px;
										a{
											padding-top: 1px;
											padding-bottom: 1px;
											font-size: 14px;
										}
									}
								}
							}
						}
						&:nth-of-type(3n+2){
							padding-left: 57px;
							width: 353px;
						}
						&:nth-of-type(3n+3){
							width: 335px;
						}
						&:nth-of-type(3n+4){
							width: 312px;
						}
						&:first-of-type{
							margin-top: 0;
							width: 1000px;
							a{
								width: 117px;
							}
						}
					}
				}
			}
			#ContBox01{
				.innerBasic{
					padding: 69px 0 160px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 29px;
				}
				.wrap{
					margin-top: 19px;
					padding-top: 60px;
				}
			}
		}
	}

	/*
	業績一覧（トップページ）
	-------------------------------------*/
	#PageAchievement.pageIndex{
	}

	/*
	業績一覧（サブカテゴリアーカイブ）
	-------------------------------------*/
	#PageAchievement.pageCategory{
	}

	/*
	業績一覧（記事詳細）
	-------------------------------------*/
	#PageAchievement.pageEntry{
		#Main{
			#ContBox01{
				.innerBasic{
					padding-right: 100px;
					padding-left: 100px;
					padding-bottom: 160px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 52px;
				}
				.titleTypeEntry{
					margin-bottom: 5px;
				}
			}
		}
	}

	/*
	お知らせ
	-------------------------------------*/
	#PageNews{
		#Main{
			.listTypeCat{
				padding-top: 76px;
				padding-bottom: 62px;
				min-width: 1000px;
				ul{
					text-align: center;
					font-size: 0;
					li{
						display: inline-block;
						margin-bottom: 10px;
						a{
							position: relative;
							text-decoration: none;
							padding-right: 29px;
							padding-left: 29px;
							&:hover{
								text-decoration: underline;
							}
							&::after{
								content: "";
								position: absolute;
								top: 50%;
								right: 0;
								transform: translate(0, -50%);
								display: block;
								width: 1px;
								height: 12px;
								background-color: #e5e5e5;
							}
						}
						&:last-of-type{
							a{
								&::after{
									display: none;
								}
							}
						}
					}
				}
			}
			#ContBox01{
				.innerBasic{
					padding: 69px 100px 160px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 40px;
				}
				.wrap{
					margin-top: 14px;
					padding-top: 60px;
				}
			}
			.pageMove{
				ul{
					li{
						a{
							&:hover{
								background-color: #fff;
								color: #77d4ff;
							}
						}
						&.prev{
							a{
								&:hover{
									span{
										&:after{
											background-image: url("../img/contents/arrow_01_b_r.png");
										}
									}
								}
							}
						}
						&.next{
							a{
								&:hover{
									span{
										&:after{
											background-image: url("../img/contents/arrow_01_b.png");
										}
									}
								}
							}
						}
						&.top{
							a{
								&:hover{
									span{
										&:after{
											background-image: url("../img/contents/arrow_02_b.png");
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	/*
	お知らせ（トップページ）
	-------------------------------------*/
	#PageNews.pageIndex{
	}

	/*
	お知らせ（サブカテゴリアーカイブ）
	-------------------------------------*/
	#PageNews.pageCategory{
	}

	/*
	お知らせ（記事詳細）
	-------------------------------------*/
	#PageNews.pageEntry{
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 206px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 49px;
				}
				.titleTypeEntry{
					margin-bottom: 10px;
				}
			}
		}
	}
}

@media print,screen and (max-width: 767px) {
	/*
	トップページ
	-------------------------------------*/
	#Page.pageIndex {
		#MainImg{
			height: 572px;
			background-position: left top, center center;
			background-image: url("../img/contents/top/shape_01_sp.png"), url("../img/contents/top/index_mian_sp.jpg");
			#MainImgInner{
				height: auto;
				h2{
					left: 15px;
					bottom: 83px;
					b{
						margin-bottom: 16px;
						padding-bottom: 8px;
						letter-spacing: -.05em;
						font-size: 27px;
						&::after{
							width: 75px;
							height: 3px;
						}
					}
					&> span{
						margin-bottom: 15px;
						letter-spacing: .1em;
						font-size: 14px;
					}
					em{
						span{
							padding: 13px 17px 10px;
							font-size: 40px;
							letter-spacing: .16em;
							&:first-of-type{
								margin-bottom: 15px;
								padding-right: 8px;
							}
							&:last-of-type{
								padding-right: 0;
							}
						}
					}
					.btnTypeDonate{
						margin-top: 30px;
					}
				}
				p{
					right: 0;
					/*bottom: -35px;*/
					top: 536px;
					width: calc(100% - 15px);
					text-indent: 0;
					img{
						width: 100%;
						height: auto;
					}
				}
			}
		}
		#Main{
			padding-top: 95px;
			.titleTypeBasic{ 
				margin-bottom: 37px;
				span{
					margin-top: 11px;
					letter-spacing: .15em;
				}
			}
			#ContBox01, #ContBox06{
				.titleTypeBasic{
					margin: 0 -15px 36px;
					padding-top: 56px;
					padding-bottom: 54px;
					background-repeat: repeat-y;
					background-position: right top;
					background-image: url("../img/contents/wall_01.jpg");
					background-size: 30px 1px;
				}
				.listTypeBlog{
					&> li{
						padding-top: 10px;
						padding-bottom: 10px;
						dl{
							dd.title{
								line-height: 24px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
							}
						}
					}
				}
				.btnTypeGradation{
					margin-top: 32px;
					&::after{
						width: 6px;
						height: 11px;
						background-image: url(../img/contents/arrow_01_w.png);
					    background-size: 6px auto;
					}
				}
			}
			#ContBox02{
				padding-top: 74px;
				.titleTypeBasic{
					margin-bottom: 90px;
					letter-spacing: .05em;
				}
				.contSubBox01{
					margin-right: -15px;
					margin-left: -15px;
					padding-bottom: 50px;
					background-color: #f2f2f2;
					.imgBox{
						position: relative;
						top: -61px;
						margin: 0 auto;
						max-width: 285px;
						p{
							margin-top: 9px;
						}
					}
					.textBox{
						margin-top: -30px;
						padding-right: 15px;
						padding-left: 15px;
						h4{
							margin-bottom: 19px;
							text-align: center;
						}
						.btnTypeDonate{
							margin-top: 30px;
							width: auto;
						}
					}
				}
			}
			#ContBox03{
				padding-top: 73px;
				.contSubBox{
					.subBox{
						&.subBox01{
							height: 375px;
							.btnTypeInv{
								margin-top: 3px;
								margin-bottom: 14px;
								width: 100px;
							}
						}
						&.subBox02{
							padding: 26px 15px 66px;
							ul{
								margin-top: 7px;
								li{
									margin-bottom: 10px;
									.btnTypeGradation{
										border-radius: 22px;
										padding-top: 8px;
										height: 45px;
									}
								}
							}
						}
					}
				}
				.contSubBox04{
					.btnTypeGradation{
						&::before{
							bottom: -12px;
							left: 50%;
							margin-left: -109px;
							width: 500px;
							height: 56px;
							background-size: 500px auto;
						}
					}
					.wrapBox{
						padding: 21px 15px 60px;
						.subBox{
							padding-top: 30px;
							padding-bottom: 20px;
							&:last-of-type{
								padding-bottom: 0;
							}
							.imgBox{
								margin: 0 auto 24px;
								max-width: 180px;
							}
							.textBox{
								h5{
									margin-bottom: -4px;
									line-height: 31px;
									letter-spacing: -.001em;
								}
								span{
									margin-bottom: 12px;
								}
							}
						}
					}
				}
			}
			#ContBox04{
				.innerBasic{
					padding-top: 74px;
					padding-bottom: 82px;
				}
				.titleTypeBasic{
					margin-bottom: 23px;
				}
				.contSubBox{
					margin-bottom: 27px;
					.subBox{
						margin-bottom: 10px;
						padding: 30px 30px 10px;
						&:last-of-type{
							margin-bottom: 0;
						}
						.imgBox{
							margin-bottom: 19px;
							dl{
								dt{
									position: relative;
									overflow: hidden;
									margin: 0 auto 20px;
									max-width: 285px;
									&::before{
										content: "";
										display: block;
										padding-top: 100%;
									}
									img{
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										left: 0;
									}
								}
								dd{
									span{
										margin-top: 11px;
									}
								}
							}
						}
						.infoBox{
							table, tbody, tr, th, td{
								display: block;
							}
							table{
								tr{
									th{
										margin-bottom: 5px;
										border-top: 1px solid #fff;
										padding-top: 12px;
									}
									td{
										padding-bottom: 11px;
										.textTypeUrl{
											display: inline-block;
											word-break: break-all;
										}
										a { word-break: break-all; }
									}
								}
							}
						}
					}
					&.contSubBox04{
						.subBox{
							padding-bottom: 20px;
						}
					}
					&.contSubBox05, &.contSubBox06{
						.subBox01{
							padding-bottom: 12px;
							ul{
								margin-top: -6px;
								margin-bottom: 6px;
								line-height: 26px;
								li{
									&::after{
										padding-right: 15px;
										padding-left: 16px;
									}
								}
							}
						}
					}
				}
				
			}
			#ContBox05{
				.innerBasic{
					padding-top: 62px;
					padding-bottom: 101px;
				}
				.titleTypeBasic{
					margin-right: -15px;
					margin-bottom: 33px;
					margin-left: -15px;
					line-height: 1.5;
					font-size: 48px;
					span{
						margin-top: -7px;
					}
				}
				.listTypeArc{
					padding-bottom: 30px;
				}
				.btnTypeGradation{
					margin-top: 50px;
					&::after{
						right: 24px;
					}
				}
			}
			#ContBox06{
				margin-top: 48px;
				margin-bottom: 80px;
			}
		}
	}

	/*
	テロメア研究
	-------------------------------------*/
	#PageTelomere.pageIndex {
		.researchBox{
			.contBox{
				&::before{
					width: 187px;
					height: 27px;
					background-size: 187px auto;
				}
				&:nth-of-type(2n){
					&::before{
						margin-left: -187px;
					}
				}
			}
		}
	}

	/*
	膵臓がん研究
	-------------------------------------*/
	#PagePancreas.pageIndex {
		.researchBox{
			.contBox{
				&::before{
					width: 188px;
					height: 22px;
					background-size: 188px auto;
				}
				&:nth-of-type(2n){
					&::before{
						margin-left: -188px;
					}
				}
			}
		}
	}

	/*
	諸臓器のがん
	-------------------------------------*/
	#PageElderly.pageIndex {
		.researchBox{
			.contBox{
				&::before{
					width: 166px;
					height: 63px;
					background-image: url("../img/contents/elderly/Various organs _sp.png");
					background-size: 166px auto;
				}
				&:nth-of-type(2n){
					&::before{
						margin-left: -186px;
					}
				}
			}
		}
	}

	/*
	業績一覧
	-------------------------------------*/
	#PageAchievement{
		#Main{
			.listTypeCat{
				padding: 30px 0 48px;
				&> ul{
					padding: 0 30px;
					&> li{
						width: 100%;
						dl{
							dt{
								position: relative;
								border-bottom: 1px solid #eee;
								padding-top: 12px;
								padding-right: 20px;
								padding-bottom: 12px;
								&::after{
									content: "";
									position: absolute;
									top: 50%;
									right: 0;
									transform: translate(0, -50%);
									display: block;
									width: 10px;
									height: 10px;
									background-repeat: no-repeat;
									background-position: center center;
									background-image: url("../img/contents/plus_02.png");
									background-size: 10px auto;
								}
								&.active{
									border-bottom: none;
									&::after{
										height: 1px;
										background-image: url("../img/contents/minus_02.png");
									}
								}
							}
							dd{
								margin-right: -30px;
								margin-left: -30px;
								padding: 11px 30px 11px;
								background-color: #fafafa;
								ul{
									li{
										a{
											padding-top: 2px;
											padding-right: 20px;
											padding-bottom: 2px;
										}
									}
								}
							}
						}
						&:first-of-type{
							border-bottom: 1px solid #eee;
							padding-top: 12px;
							padding-bottom: 12px;
						}
					}
				}
			}
			#ContBox01{
				.innerBasic{
					padding-top: 59px;
					padding-bottom: 100px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 9px;
				}
				.wrap{
					margin-top: 30px;
					padding-top: 50px;
				}
			}
		}
	}

	/*
	業績一覧（トップページ）
	-------------------------------------*/
	#PageAchievement.pageIndex{
	}

	/*
	業績一覧（サブカテゴリアーカイブ）
	-------------------------------------*/
	#PageAchievement.pageCategory{
	}

	/*
	業績一覧（記事詳細）
	-------------------------------------*/
	#PageAchievement.pageEntry{
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 32px;

				}
				.titleTypeEntry{
					margin-bottom: 0;
					line-height: 36px;
				}
			}
		}
	}

	/*
	お知らせ
	-------------------------------------*/
	#PageNews{
		#Main{
			.listTypeCat{
				padding: 40px 15px;
				ul{
					border-top: 1px solid #e5e5e5;
					border-left: 1px solid #e5e5e5;
					&::after{
						content:"";
						display:block;
						clear:both;
					}
					li{
						float: left;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						width: 33.3333333333%;
						border-right: 1px solid #e5e5e5;
						border-bottom: 1px solid #e5e5e5;
						a{
							padding: 13px 5px;
							line-height: 1.2;
							text-align: center;
						}
					}
				}
			}
			#ContBox01{
				.innerBasic{
					padding-top: 59px;
					padding-bottom: 100px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 21px;
				}
				.wrap{
					margin-top: 12px;
					padding-top: 50px;
				}
			}
		}
	}

	/*
	お知らせ（トップページ）
	-------------------------------------*/
	#PageNews.pageIndex{
	}

	/*
	お知らせ（サブカテゴリアーカイブ）
	-------------------------------------*/
	#PageNews.pageCategory{
	}

	/*
	お知らせ（記事詳細）
	-------------------------------------*/
	#PageNews.pageEntry{
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.titleTypeUnderBorder{
					margin-bottom: 32px;

				}
				.titleTypeEntry{
					margin-bottom: 7px;
					line-height: 36px;
				}
			}
		}
	}
}

@media print,screen and (max-width: 340px) {
	/*
	トップページ
	-------------------------------------*/
	#Page.pageIndex {
		#MainImg{
			#MainImgInner{
				h2{
					b{
						font-size: 27px;
					}
					&> span{
						letter-spacing: 0;
						font-size: 14px;
					}
					em{
						span{
							font-size: 30px;
						}
					}
				}
			}
		}
	}
}
@media print,screen and (max-width: 320px) {
	/*
	トップページ
	-------------------------------------*/
	#Page.pageIndex {
		#Main{
			#ContBox05{
				.titleTypeBasic{
					font-size: 40px;
				}
			}
		}
	}
}

